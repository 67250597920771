import { UpdateVariableDto } from "./dto/update-variable.dto";

export function updateVariable(id: string, variable: UpdateVariableDto) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/variables/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(variable),
  });
}
