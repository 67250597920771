import { Range } from "models/variable.model";
import React from "react";

interface RangesFormProps {
  ranges: Range[];
  setRanges: (ranges: Range[]) => void;
}

export default function RangesForm({ ranges, setRanges }: RangesFormProps) {
  const handleAddRange = () => {
    setRanges([...ranges, { min: 0, max: 0, title: "", color: "" }]);
  };

  const handleRemoveRange = (index: number) => {
    setRanges(ranges.filter((_, i) => i !== index));
  };

  const handleRangeChange = (
    index: number,
    key: "min" | "max" | "title" | "color",
    value: number | string
  ) => {
    setRanges(
      ranges.map((range, i) =>
        i === index ? { ...range, [key]: value } : range
      )
    );
  };

  return (
    <div className="pt-4 pb-8">
      <div className="flex gap-2 items-center">
        <div>Rangos de la variable</div>
        <div className="flex-auto"></div>
        <button
          className="border-[#223a65] border-2 border rounded-lg p-1 w-8 h-8 flex items-center justify-center"
          type="button"
          onClick={handleAddRange}
        >
          +
        </button>
      </div>
      <details>
        <summary>Explicación de rangos</summary>

        <p>
          Los rangos de la variable permiten mostrar unos colores a modo de
          semáforo en la visualización de los datos. Esto se visualiza
          actualmente en el popup que muestra la última medición de una estación
          en el mapa.
        </p>
      </details>
      <div className="grid grid-cols-[50px,50px,1fr,50px,20px] gap-1 items-center">
        {ranges.length > 0 && (
          <React.Fragment>
            <div>Mín.</div>
            <div>Máx.</div>
            <div>Título</div>
            <div>Color</div>
            <div></div>
          </React.Fragment>
        )}

        {ranges.map((range, index) => (
          <React.Fragment key={index}>
            <input
              type="number"
              placeholder="Mínimo"
              value={range.min}
              onChange={(e) => handleRangeChange(index, "min", +e.target.value)}
              inputMode="numeric"
              className="p-1 rounded-lg border"
            />
            <input
              type="number"
              placeholder="Máximo"
              value={range.max}
              onChange={(e) => handleRangeChange(index, "max", +e.target.value)}
              inputMode="numeric"
              className="p-1 rounded-lg border"
            />
            <input
              type="text"
              placeholder="Título"
              value={range.title}
              onChange={(e) =>
                handleRangeChange(index, "title", e.target.value)
              }
              className="p-1 rounded-lg border"
            />
            <input
              type="color"
              placeholder="Color"
              value={range.color}
              onChange={(e) =>
                handleRangeChange(index, "color", e.target.value)
              }
              className="p-1 rounded-lg border"
            />
            <button
              className="text-red-500 font-bold"
              type="button"
              onClick={() => handleRemoveRange(index)}
            >
              X
            </button>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
