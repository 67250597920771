import { getLastSensorData } from "api/orion/sensor-data/get-sensor-data";
import React, { FC, useState, useEffect } from "react";
import { SensorPopupProps } from "./sensor-popup";
import { format } from "date-fns";
import { getVariables } from "api/variables/get-variables";
import { VariableDto } from "api/variables/dto/variable.dto";
import { RangeDto } from "api/variables/dto/range.dto";
import { IcaValueComputed } from "models/ica";
import { getIcaForSensor } from "api/air-quality-range/get-ica-for-sensor";

export function AirQualitySensorPopup({
  sensor,
  setSelectedSensor,
  setIsSidePanelOpen,
}: SensorPopupProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [VARIABLES_MAP, setVariablesMap] = useState<Map<string, VariableDto>>(
    new Map()
  );
  const [rangesMap, setRangesMap] = useState<Map<string, RangeDto[]>>(
    new Map()
  );
  const [icaValues, setIcaValues] = useState<{
    [variable: string]: IcaValueComputed;
  } | null>(null);

  useEffect(() => {
    Promise.all([getVariables(), getIcaForSensor(sensor._id)])
      .then(([[vars, variablesOk], [icaValuesForSensor, icaValuesOk]]) => {
        if (variablesOk && icaValuesOk && vars && icaValuesForSensor) {
          const VARIABLES_MAP = new Map<string, VariableDto>(
            vars.map((v: VariableDto) => [
              v.name,
              {
                ...v,
                title: v.unit ? `${v.title} (${v.unit})` : v.title,
              } satisfies VariableDto,
            ])
          );

          const newRangesMap = new Map<string, RangeDto[]>();
          for (const variable of vars) {
            newRangesMap.set(variable.name, variable.ranges ?? []);
          }
          setRangesMap(newRangesMap);

          setIcaValues(icaValuesForSensor);

          setVariablesMap(VARIABLES_MAP);
          setIsLoading(false);
          return;
        }

        setError("Error al obtener las variables");
        setIsLoading(false);
      })
      .catch((error) => {
        setError("Error al obtener las variables");
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <div>Cargando...</div>;

  if (error) return <div>{error}</div>;

  return (
    <div className="w-full">
      <h3 className="font-black text-lg mb-4">{sensor.name}</h3>

      <SensorLastDatumTable
        sensorId={sensor._id}
        variables={sensor.variables.split(",")}
        variablesMap={VARIABLES_MAP}
        icaValues={icaValues}
        rangesMap={rangesMap}
      ></SensorLastDatumTable>

      <button
        onClick={() => {
          setSelectedSensor(sensor);
          setIsSidePanelOpen(true);
        }}
        className="mt-4 mompox-blue text-white px-4 py-1 rounded w-full"
      >
        Ver datos
      </button>
    </div>
  );
}

interface SensorLastDatumTableProps {
  sensorId: string;
  variables: string[];
  variablesMap: Map<string, VariableDto>;
  icaValues: { [variable: string]: IcaValueComputed } | null;
  rangesMap: Map<string, RangeDto[]>;
}

const SensorLastDatumTable: FC<SensorLastDatumTableProps> = ({
  sensorId,
  variables,
  variablesMap,
  icaValues,
  rangesMap,
}: SensorLastDatumTableProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [sensorData, setSensorData] = useState<any | null>();

  useEffect(() => {
    getLastSensorData(sensorId)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const [datum] = data;

            setSensorData(datum ?? null);
            setIsLoading(false);
          });
        } else {
          setErrorMsg("Error al obtener los datos del sensor");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setErrorMsg("Error al obtener los datos del sensor");
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <div>Cargando...</div>;

  if (errorMsg) return <div>{errorMsg}</div>;

  if (!sensorData) return <div>No hay datos disponibles</div>;

  return (
    <div className="w-full mt-4 grid grid-cols-[1fr,100px,20px] text-[9px]">
      <div className="border-b border-b-1 border-b-black/50 col-span-3 text-center p-1 font-bold">
        Último dato del sensor
      </div>
      <div className="border-b border-b-1 border-b-black/50  p-1 font-bold">
        Variable
      </div>
      <div className="border-b border-b-1 border-b-black/50 text-center  p-1 font-bold">
        Valor
      </div>
      <div className="border-b border-b-1 border-b-black/50 text-center  p-1 font-bold"></div>

      {!!sensorData["date"] && (
        <>
          <div className="border-b border-b-1 border-b-black/50 p-1">Fecha</div>
          <div className="border-b border-b-1 border-b-black/50 text-right p-1">
            {format(new Date(sensorData["date"]["value"]), "yyyy-MM-dd HH:mm")}
          </div>
          <div className="border-b border-b-1 border-b-black/50 text-right p-1"></div>
        </>
      )}

      {variables
        .filter(
          (variable) => sensorData[variable] && sensorData[variable]["value"]
        )
        .map((variable: any) => (
          <React.Fragment key={variable}>
            <div className="border-b border-b-1 border-b-black/50 p-1">
              {variablesMap.get(variable)
                ? variablesMap.get(variable)!.title
                : ""}
            </div>
            <div className="border-b border-b-1 border-b-black/50 text-right p-1">
              {Intl.NumberFormat("es-CO").format(sensorData[variable]["value"])}
            </div>
            <div>
              <AirQualitySensorColor
                variable={variable}
                value={sensorData[variable]["value"]}
                rangesMap={rangesMap}
                icaValues={icaValues}
              />
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};

interface AirQualitySensorColorProps {
  variable: string;
  value: number;
  rangesMap: Map<string, RangeDto[]>;
  icaValues: { [variable: string]: IcaValueComputed } | null;
}

/**
 * Component that renders the color of the air quality sensor based on the value and the ranges.
 * If the variable has ICA ranges, it will use the ranges to determine the color.
 * If the variable does not have ICA ranges, it will try to determine the color based on the ranges
 * of the variable. If the variable has no ranges, it will leave the cell blank.
 */
const AirQualitySensorColor: FC<AirQualitySensorColorProps> = ({
  variable,
  value,
  rangesMap,
  icaValues,
}: AirQualitySensorColorProps) => {
  const ranges: RangeDto[] | undefined = rangesMap.get(variable) ?? [];
  const icaValue = icaValues ? icaValues[variable] : null;

  function getBgStyleReg() {
    const range = ranges!.find((r) => r.min <= value && r.max >= value);

    if (!range) return {};

    return {
      backgroundColor: range.color,
    };
  }

  if (!ranges.length && !icaValue) {
    return (
      <div className="border-b border-b-1 border-b-black/50 text-right p-1 h-full"></div>
    );
  }

  if (icaValue) {
    return (
      <div
        className="border-b border-b-1 border-b-black/50 text-right p-1 h-full"
        style={{
          backgroundColor: icaValue.color,
        }}
      ></div>
    );
  }

  if (ranges.length) {
    return (
      <div
        className="border-b border-b-1 border-b-black/50 text-right p-1 h-full"
        style={getBgStyleReg()}
      ></div>
    );
  }

  return <div></div>;
};
