"use client";
import { MapContainer, Marker, Popup, useMap } from "react-leaflet";
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.gridlayer.googlemutant";
import { FC, useEffect, useState } from "react";
import { MapStyle } from "./map-style";
import { getSensorDataCSV } from "api/sensor/get-csv-data";
import { format, subDays } from "date-fns";
import { SensorLayer } from "models/sensor-layer.model";
import { SENSOR_TYPE_MARKER_ICON_MAP, SENSOR_TYPE_POPUP_MAP, SENSOR_TYPES } from "constants/sensor-types";
import { Sensor } from "models/sensor.model";
import LayerMenu from "./layer-menu";
import { useGoogleMaps } from "hooks/use-google-maps";
import { SensorPopup, SensorPopupProps } from "./forms/sensor/sensor-popup";

const MapComponent = () => {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(null);
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [layers, setLayers] = useState<SensorLayer[]>([]);

  const [layerStatuses, setLayerStatuses] = useState<{
    [key: string]: boolean;
  }>(SENSOR_TYPES.reduce((acc, type) => ({ ...acc, [type.id]: true }), {}));

  const [filteredSensors, setFilteredSensors] = useState<Sensor[]>([]);

  useEffect(() => {
    setFilteredSensors(
      sensors.filter((sensor) => !!layerStatuses[`${sensor.type}`])
    );
  }, [layerStatuses, sensors]);

  // Obtener sensores al montar el componente
  useEffect(() => {
    const fetchSensors = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/v1/sensors`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setSensors(data);

          setLayers(
            SENSOR_TYPES.map((type) => ({
              id: type.id,
              name: type.name,
              sensors: data.filter((sensor: Sensor) => sensor.type === type.id),
              icon: type.icon,
            }))
          );
        } else {
          console.error("Error al obtener los sensores");
        }
      } catch (error) {
        console.error("Error al obtener los sensores:", error);
      }
    };

    fetchSensors();
  }, []);

  return (
    <div className="relative h-full w-full">
      <MapContainer
        center={[9.240592, -74.424925]}
        zoom={15}
        className="h-full w-full z-0"
      >
        <GoogleMutantLayer />
        {/* Mostrar sensores como marcadores */}
        {filteredSensors.map((sensor) => {
          const CurrentSensorPopup: FC<SensorPopupProps> =
            SENSOR_TYPE_POPUP_MAP.get(sensor.type) || SensorPopup;
          const currentSensorMarkerIconUrl = SENSOR_TYPE_MARKER_ICON_MAP.get(sensor.type) ?? 'marker.svg';

          const icon = new Icon({
            className: "marker-icon",
            iconUrl: currentSensorMarkerIconUrl,
            iconSize: [48, 48],
            iconAnchor: [24, 48],
            popupAnchor: [0, -48],
          });

          return (
            <Marker
              key={sensor._id}
              position={[sensor.latitude, sensor.longitude]}
              icon={icon}
            >
              <Popup maxWidth={240} minWidth={240}> 
                <CurrentSensorPopup
                  sensor={sensor}
                  setIsSidePanelOpen={setIsSidePanelOpen}
                  setSelectedSensor={setSelectedSensor}
                ></CurrentSensorPopup>
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>

      {layers && layers.length && (
        <div className="fixed top-[56px] bottom-0 right-0 h-[calc(100%-56px)] w-fit z-40 text-black flex items-center">
          <LayerMenu
            layers={layers}
            layerStatuses={layerStatuses}
            onLayerToggle={(layer: string) => {
              setLayerStatuses({
                ...layerStatuses,
                [layer]: !layerStatuses[layer],
              });
            }}
          ></LayerMenu>
        </div>
      )}

      {/* Renderizar SidePanel */}
      {isSidePanelOpen && selectedSensor && (
        <SidePanel
          sensor={selectedSensor}
          onClose={() => setIsSidePanelOpen(false)}
        />
      )}
    </div>
  );
};

export default MapComponent;

const GoogleMutantLayer = () => {
  const googleMapsApiKey = "AIzaSyCL0mNEE127NGClEWk7b6y5oSL6qq1weps";
  const googleMapsLoaded = useGoogleMaps(googleMapsApiKey);
  const map = useMap();

  useEffect(() => {
    if (googleMapsLoaded) {
      // eslint-disable-next-line
      const googleMutantLayer = (L.gridLayer as unknown as any).googleMutant({
        type: "roadmap",
        styles: MapStyle.light,
        maxZoom: 20,
        apiKey: googleMapsApiKey,
      });
      googleMutantLayer.addTo(map);
    }
  }, [map, googleMapsLoaded]);

  return null;
};

const SidePanel = ({
  sensor,
  onClose,
}: {
  sensor: Sensor;
  onClose: () => void;
}) => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());

  const handleDownload = async () => {
    try {
      // Fetch the CSV data as a blob
      const response = await getSensorDataCSV(
        sensor._id,
        startDate.toISOString(),
        endDate.toISOString()
      );

      if (!response.ok) {
        throw new Error("Error al descargar el CSV");
      }

      // Convert response to blob
      const blob = await response.blob();

      // Create a downloadable link and click it to trigger download
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `${sensor.name}_data.csv`;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error al descargar el CSV:", error);
    }
  };

  return (
    <div className="fixed top-0 right-0 h-full w-full bg-white shadow-lg z-50 p-4 text-black ">
      <h2 className="text-xl font-bold mb-4">{sensor.name}</h2>
      <div className="mb-4">
        <label className="block mb-2">Fecha inicio</label>
        <input
          type="date"
          value={format(startDate, "yyyy-MM-dd")}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          className="border p-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Fecha fin</label>
        <input
          type="date"
          value={format(endDate, "yyyy-MM-dd")}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          className="border p-2 w-full"
        />
      </div>
      <div className="flex gap-4 items-center">
        <button
          onClick={handleDownload}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Descargar CSV
        </button>
        <button
          onClick={onClose}
          className="text-gray-600 hover:text-gray-800"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};
