export const VARIABLES = [
  { label: "Temperatura", value: "temperature", unit: "°C" },
  { label: "Humedad", value: "humidity", unit: "%" },
  { label: "Presión", value: "pressure", unit: "" },
  { label: "pm2.5", value: "pm2_5", unit: "µg/m³" },
  { label: "pm10", value: "pm10", unit: "µg/m³" },
  { label: "CO2", value: "co2", unit: "ppm" },
  { label: "O3", value: "o3", unit: "ppb" },
  { label: "NO2", value: "no2", unit: "ppb" },
  { label: "SO2", value: "so2", unit: "ppb" },
  { label: "CO", value: "co", unit: "ppm" },
  { label: "Profundidad", value: "profundidad", unit: "m" },
  { label: "Oxígeno disuelto", value: "dissolved_oxygen", unit: "mg/L" },
  { label: "pH", value: "ph", unit: "" },
  { label: "Conductividad", value: "conductivity", unit: "µS/cm" },
  { label: "Clorofila", value: "chlorophyll", unit: "µg/L" },
];
