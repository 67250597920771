export function getSensors() {
  return fetch(
    `${process.env.REACT_APP_API_BASE_URL}/v1/sensors/my-sensors/all`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );
}
