import MapComponent from "components/map";
import Navbar from "components/navbar";

export default function Home() {
  if (typeof window === "undefined") return null;

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div className="h-[calc(100vh-56px)]">
        <MapComponent />
      </div>
    </div>
  );
}
