import { useAuth } from "hooks/use-auth";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const { logout, isLoggedIn, isAdmin } = useAuth();
  const navigate = useNavigate();

  const iconSize = 28; // Standardized icon size

  const NavButton = ({
    to,
    icon,
    label,
    onClick,
  }: {
    to: string;
    icon: string;
    label: string;
    onClick?: () => void;
  }) => (
    <Link to={to}>
      <button
        onClick={onClick}
        className="h-10 px-2 flex items-center gap-2 hover:bg-opacity-10 hover:bg-white rounded-md"
      >
        <img
          aria-hidden
          src={icon}
          alt={label}
          width={iconSize}
          height={iconSize}
          className="object-contain"
        />
        <span className="hidden lg:block">{label}</span>
      </button>
    </Link>
  );

  return (
    <div className="h-14 flex items-center gap-2 px-4 mompox-blue">
      <Link className="flex items-center" to="/">
        <img
          aria-hidden
          src="/mompox.svg"
          alt="App logo"
          width={96}
        />
      </Link>

      <div className="flex-auto" />

      {isLoggedIn ? (
        <div className="flex items-center gap-2">
          {isAdmin && (
            <NavButton
              to="/admin/configuration"
              icon="/icons/iconos plataforma mompox escritorio-23.svg"
              label="Configuración"
            />
          )}
          <NavButton
            to="/my-sensors"
            icon="/icons/iconos plataforma mompox escritorio-26.svg"
            label="Mis sensores"
          />
          <NavButton
            to="/profile"
            icon="/icons/iconos plataforma mompox escritorio-25.svg"
            label="Perfil"
          />
          <NavButton
            to="/"
            icon="/icons/iconos plataforma mompox escritorio-27.svg"
            label="Cerrar sesión"
            onClick={() => {
              logout();
              navigate("/");
            }}
          />
        </div>
      ) : (
        <Link to="/login">
          <button className="h-10 px-4 rounded-md hover:bg-opacity-10 hover:bg-white">
            Ingresar
          </button>
        </Link>
      )}
    </div>
  );
};

export default Navbar;
