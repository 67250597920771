import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/home";
import { AuthProvider } from "hooks/use-auth";
import LoginForm from "pages/login";
import RegisterForm from "pages/register";
import MySensors from "pages/sensors/sensors-list";
import Profile from "pages/profile";
import VariablesList from "pages/admin/variables/variables-list";
import { AppConfiguration } from "pages/admin/configuration";
import { IcasList } from "pages/admin/icas/icas-list";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/my-sensors" element={<MySensors />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/admin/variables" element={<VariablesList />} />
          <Route path="/admin/configuration" element={<AppConfiguration />} />
          <Route path="/admin/icas" element={<IcasList />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
