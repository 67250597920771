import { AirQualitySensorPopup } from "components/forms/sensor/air-quality-sensor-popup";
import { SensorPopup, TableSensorPopup } from "components/forms/sensor/sensor-popup";
import { WaterQualitySensorPopup } from "components/forms/sensor/water-quality-sensor-popup";

export const SENSOR_TYPES = [
  {
    id: "hotel",
    name: "Hotel",
    icon: "icons/iconos plataforma mompox escritorio-21.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-40.svg",
    popup: SensorPopup,
  },
  {
    id: "restaurant",
    name: "Restaurante",
    icon: "icons/restaurant.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-41.svg",
    popup: SensorPopup,
  },
  {
    id: "smart-point",
    name: "Punto Smart",
    icon: "icons/star.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-42.svg",
    popup: SensorPopup,
  },
  {
    id: "smart-mural",
    name: "Mural Inteligente",
    icon: "icons/lightbulb.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-38.svg",
    popup: SensorPopup,
  },
  {
    id: "water-quality",
    name: "Calidad del Agua",
    icon: "drop.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-30.svg",
    popup: WaterQualitySensorPopup,
  },
  {
    id: "air-quality",
    name: "Calidad del Aire",
    icon: "icons/iconos plataforma mompox escritorio-01.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-35.svg",
    popup: AirQualitySensorPopup,
  },
  {
    id: "wifi-zone",
    name: "Zona Wifi",
    icon: "wifi.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-33.svg",
    popup: SensorPopup,
  },
  {
    id: "water-level",
    name: "Nivel de Agua",
    icon: "water-level.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-44.svg",
    popup: TableSensorPopup,
  },
  {
    id: "drone",
    name: "Dron",
    icon: "drone.svg",
    markerIcon: "icons/iconos plataforma mompox escritorio-45.svg",
    popup: TableSensorPopup,
  },
];

export const SENSOR_TYPE_POPUP_MAP = new Map(SENSOR_TYPES.map((type) => [type.id, type.popup]));
export const SENSOR_TYPE_MARKER_ICON_MAP = new Map(SENSOR_TYPES.map((type) => [type.id, type.markerIcon]));