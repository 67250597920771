export function Instructions() {
  return (
    <details className="p-4 border border-gray-300 rounded-lg bg-gray-50">
      <summary className="font-semibold cursor-pointer">
        Cómo crear datos para el sensor
      </summary>
      <div className="mt-2 text-gray-700">
        <p>
          Para crear datos para el sensor, se debe realizar una solicitud{" "}
          <strong>POST</strong> a la siguiente URL:
        </p>
        <p className="mt-2">
          <code>https://mompox.urbagis.com/data</code>
        </p>

        <h3 className="mt-4 font-semibold">Encabezados requeridos</h3>
        <ul className="list-disc list-inside">
          <li>
            <code>x-api-key</code>: La clave API correspondiente al sensor.
          </li>
        </ul>

        <h3 className="mt-4 font-semibold">Cuerpo de la solicitud</h3>
        <p>
          El cuerpo debe ser una cadena de texto que contenga el nombre de la
          variable seguido de su valor, separados por el carácter <code>|</code>
          . Cada par <strong>nombre|valor</strong> debe estar separado por el
          mismo carácter.
        </p>

        <h4 className="mt-2 font-semibold">Ejemplo de cuerpo:</h4>
        <p>
          <code>&quot;temperatura|25|humedad|60|presión|1013&quot;</code>
        </p>
      </div>
    </details>
  );
}
