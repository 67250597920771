import { UpdateSensorDto } from "./dto/update-sensor.dto";

export function updateSensor(sensorId: string, sensorChanges: UpdateSensorDto) {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/sensors/${sensorId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sensorChanges),
    });
  }
  