import { CreateVariableDto } from "./dto/create-variable.dto";

export function importVariables(variables: CreateVariableDto[]) {
    return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/variables/import`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(variables),
    });
}