import { createVariable } from "api/variables/create-variable";
import RangesForm from "./ranges-form";
import { useState } from "react";
import { CreateRangeDto } from "api/variables/dto/create-range.dto";

export default function CreateVariableForm({
  onVariableCreated,
}: {
  onVariableCreated: () => void;
}) {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [unit, setUnit] = useState("");
  const [useRanges, setUseRanges] = useState(false);
  const [ranges, setRanges] = useState<CreateRangeDto[]>([]);
  const [error, setError] = useState("");

  const handleCreateVariable = async () => {
    setError("");

    // We must check if 'name' follows the format of a valid variable name
    // before sending it to the backend, using a regular expression
    if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(name)) {
      setError("El nombre de la variable no es válido");
      return;
    }

    try {
      const result = await createVariable({
        name,
        title,
        unit,
        useRanges,
        ranges,
      });

      if (!result.ok) {
        setError("Hubo un error al crear la variable");
        return;
      }

      onVariableCreated();
    } catch (e) {
      setError("Hubo un error al crear la variable");
    }
  };

  return (
    <div className="flex gap-2 flex-col p-2 text-black">
      <div className="flex flex-col gap-1">
        <label>Nombre (para subida de datos)</label>
        <input
          type="text"
          placeholder="Nombre"
          value={name}
          className="p-2 rounded-lg border"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Título (para mostrar en reportes)</label>
        <input
          type="text"
          placeholder="Título"
          value={title}
          className="p-2 rounded-lg border"
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Unidad</label>
        <input
          type="text"
          placeholder="Unidad"
          value={unit}
          className="p-2 rounded-lg border"
          onChange={(e) => setUnit(e.target.value)}
        />
      </div>
      <div className="flex gap-2 items-center">
        <label>Usa rangos?</label>
        <input
          type="checkbox"
          checked={useRanges}
          className="p-2 rounded-lg border h-4 w-4 mt-1"
          onChange={(e) => setUseRanges(e.target.checked)}
        />
      </div>
      {useRanges && <RangesForm ranges={ranges} setRanges={setRanges} />}
      <button
        className="mompox-blue text-white p-2 rounded-lg"
        onClick={handleCreateVariable}
      >
        Crear variable
      </button>
      {error && <div>{error}</div>}
    </div>
  );
}
