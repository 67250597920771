import { ApiResponse } from "@models/api-response";
import { AirQualityRangeDto } from "./dto/air-quality-range.dto";

export async function getAirQualityRanges(): Promise<
  ApiResponse<AirQualityRangeDto[]>
> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/air-quality-ranges`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    const result = await response.json();
    return [result, true];
  } catch (error) {
    return [null, false];
  }
}
