import { createAirQualityRange } from "api/air-quality-range/create-air-quality-range";
import { useState, FormEvent } from "react";

export default function CreateAirQualityRangeForm({
  onAirQualityRangeCreated,
}: {
  onAirQualityRangeCreated: () => void;
}) {
  const [title, setTitle] = useState("");
  const [periodicity, setPeriodicity] = useState("");
  const [variable, setVariable] = useState("");
  const [I_low, setI_low] = useState(0);
  const [I_high, setI_high] = useState(0);
  const [BP_low, setBP_low] = useState(0);
  const [BP_high, setBP_high] = useState(0);
  const [quality_title, setQuality_title] = useState("");
  const [quality_color, setQuality_color] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      title,
      periodicity,
      variable,
      I_low,
      I_high,
      BP_low,
      BP_high,
      quality_title,
      quality_color,
    };
    // Create the air quality range
    const [, ok] = await createAirQualityRange(data);

    if (ok) {
      onAirQualityRangeCreated();
      return;
    }

    setError(
      "Hubo un error al crear el rango de calidad del aire, intente de nuevo."
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-black">
      <h2 className="text-2xl text-mompox-blue font-bold">Nuevo registro</h2>
      <div className="max-h-[600px] overflow-y-auto">
        <div>Título</div>
        <input
          type="text"
          className="p-2 rounded-lg border"
          id="title"
          value={title}
          placeholder="Título"
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <div>Periodicidad</div>
        <input
          type="text"
          className="p-2 rounded-lg border"
          id="periodicity"
          value={periodicity}
          onChange={(e) => setPeriodicity(e.target.value)}
          required
          placeholder="Periodicidad"
        />
        <div>Variable</div>
        <input
          type="text"
          className="p-2 rounded-lg border"
          id="variable"
          value={variable}
          onChange={(e) => setVariable(e.target.value)}
          required
          placeholder="Variable"
        />
        <div>I_low</div>
        <input
          type="number"
          className="p-2 rounded-lg border"
          id="I_low"
          value={I_low}
          onChange={(e) => setI_low(parseInt(e.target.value))}
          required
          placeholder="I_low"
        />
        <div>I_high</div>
        <input
          type="number"
          className="p-2 rounded-lg border"
          id="I_high"
          value={I_high}
          onChange={(e) => setI_high(parseInt(e.target.value))}
        />
        <div>BP_low</div>
        <input
          type="number"
          className="p-2 rounded-lg border"
          id="BP_low"
          value={BP_low}
          onChange={(e) => setBP_low(parseInt(e.target.value))}
          required
          placeholder="BP_low"
        />
        <div>BP_high</div>
        <input
          type="number"
          className="p-2 rounded-lg border"
          id="BP_high"
          value={BP_high}
          onChange={(e) => setBP_high(parseInt(e.target.value))}
          required
          placeholder="BP_high"
        />
        <div>Título calidad</div>
        <input
          type="text"
          className="p-2 rounded-lg border"
          id="quality_title"
          value={quality_title}
          onChange={(e) => setQuality_title(e.target.value)}
          required
          placeholder="Título calidad"
        />
        <div>Color calidad</div>
        <input
          type="color"
          className="p-1 rounded-lg border"
          id="quality_color"
          value={quality_color}
          onChange={(e) => setQuality_color(e.target.value)}
          required
          placeholder="Color calidad"
        />
      </div>
      <button type="submit" className="mompox-blue text-white p-2 rounded-lg">
        Crear registro
      </button>

      {error && <p className="text-red-500 mt-2">{error}</p>}
    </form>
  );
}
