import { CreateSensorDto } from "./dto/create-sensor.dto";

export function createSensor(newSensor: CreateSensorDto) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/sensors`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newSensor),
  });
}
