export function getSensorDataCSV(
  id: string,
  startDate: string,
  endDate: string
) {
  const url = `${
    process.env.REACT_APP_API_BASE_URL
  }/v1/sensors/${id}/data/csv?startDate=${encodeURIComponent(
    startDate
  )}&endDate=${encodeURIComponent(endDate)}`;

  // Fetch the CSV data as a blob
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "text/csv",
    },
  });
}
