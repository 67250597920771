import { SensorLayer } from "models/sensor-layer.model";
import { FC } from "react";

export default function LayerMenu(props: {
  layers: SensorLayer[];
  layerStatuses: { [key: string]: boolean };
  onLayerToggle: (layer: string) => void;
}) {
  const { layers, layerStatuses, onLayerToggle } = props;

  return (
    <div className="mompox-blue rounded-lg shadow-lg h-[80vh] max-h-[600px] w-[60px] flex flex-col p-2">
      {layers.map((layer) => (
        <LayerMenuIcon
          key={layer.name}
          sensorLayer={layer}
          isActive={layerStatuses[layer.id]}
          onLayerToggle={() => onLayerToggle(layer.id)}
        />
      ))}
    </div>
  );
}

interface LayerMenuIconProps {
  sensorLayer: SensorLayer;
  isActive: boolean;
  onLayerToggle: () => void;
}

const LayerMenuIcon: FC<LayerMenuIconProps> = (props) => {
  const { sensorLayer, isActive, onLayerToggle } = props;
  return (
    <div className="group relative flex-1 flex items-center justify-center min-h-0 min-w-0">
      <img
        onClick={onLayerToggle}
        className={`w-full h-full object-contain cursor-pointer ${
          !isActive ? "opacity-50" : ""
        }`}
        src={sensorLayer.icon}
        alt={sensorLayer.name}
      />

      {/* Tooltip - positioned to the left */}
      <div
        className="absolute right-full mr-2 px-2 py-1 mompox-blue text-white text-sm rounded 
                    opacity-0 group-hover:opacity-100 whitespace-nowrap z-50 pointer-events-none
                    transition-opacity duration-200
                    before:content-[''] before:absolute before:top-1/2 before:left-full 
                    before:-translate-y-1/2 before:border-8 before:border-transparent 
                    before:border-l-[#F6AA0F]"
      >
        {sensorLayer.name}
      </div>
    </div>
  );
};
