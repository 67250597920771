"use client";
import { register } from "api/auth/register";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await register(email, password);
      const data = await response.json();
      if (response.ok) {
        navigate("/login");
      } else {
        setError(data.message || "Error creando usuario.");
      }
    } catch (err) {
      console.log(err);
      setError("Ha ocurrido un error, intente nuevamente.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen text-black">
      <form
        className="max-w-2xl w-[80%] min-w-[300px] flex flex-col bg-white/70 rounded-lg p-4 gap-2"
        onSubmit={handleLogin}
      >
        <img
          aria-hidden
          src="/mompox.svg"
          alt="App logo"
          width={256}
          height={128}
          className="mx-auto"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="bg-[#fcc45333] text-black p-2 rounded-lg"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
          required
          className="bg-[#fcc45333] text-black p-2 rounded-lg"
        />
        <button className="bg-[#1c295d] rounded-lg p-2 text-white" type="submit">Registrarse</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default RegisterForm;
