import { ApiResponse } from "@models/api-response";
import { AirQualityRangeDto } from "./dto/air-quality-range.dto";
import { CreateAirQualityRangeDto } from "./dto/create-air-quality-range.dto";

export async function createAirQualityRange(data: CreateAirQualityRangeDto): Promise<ApiResponse<AirQualityRangeDto>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/air-quality-ranges`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    const result = await response.json();
    return [result, true];
  } catch (error) {
    return [null, false];
  }
}
