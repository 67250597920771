import { getLastSensorData } from "api/orion/sensor-data/get-sensor-data";
import React, { FC, useState, useEffect } from "react";
import { SensorPopupProps } from "./sensor-popup";
import { format } from "date-fns";
import { getVariables } from "api/variables/get-variables";
import { VariableDto } from "@api/variables/dto/variable.dto";

export function WaterQualitySensorPopup({
  sensor,
  setSelectedSensor,
  setIsSidePanelOpen,
}: SensorPopupProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [allVariables, setAllVariables] = useState<VariableDto[]>([]);
  const [VARIABLES_MAP, setVariablesMap] = useState<Map<string, string>>(
    new Map()
  );

  useEffect(() => {
    getVariables()
      .then(([variables, ok]) => {
        if (ok) {
          setAllVariables(variables);
          const VARIABLES_MAP = new Map<string, string>(
            variables.map((v: VariableDto) => [
              v.name,
              v.unit ? `${v.title} (${v.unit})` : v.title,
            ])
          );
          setVariablesMap(VARIABLES_MAP);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setError("Error al obtener las variables");
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <div>Cargando...</div>;

  if (error) return <div>{error}</div>;

  return (
    <div className="w-full">
      <h3 className="font-black text-lg mb-4">{sensor.name}</h3>

      <SensorLastDatumTable
        sensorId={sensor._id}
        variables={sensor.variables.split(",")}
        allVariables={allVariables}
        variablesMap={VARIABLES_MAP}
      ></SensorLastDatumTable>

      <button
        onClick={() => {
          setSelectedSensor(sensor);
          setIsSidePanelOpen(true);
        }}
        className="mt-4 mompox-blue text-white px-4 py-1 rounded w-full"
      >
        Ver datos
      </button>
    </div>
  );
}

interface SensorLastDatumTableProps {
  sensorId: string;
  variables: string[];
  allVariables: VariableDto[];
  variablesMap: Map<string, string>;
}

const SensorLastDatumTable: FC<SensorLastDatumTableProps> = ({
  sensorId,
  variables,
  allVariables,
  variablesMap,
}: SensorLastDatumTableProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [sensorData, setSensorData] = useState<any | null>();

  useEffect(() => {
    getLastSensorData(sensorId)
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            const [datum] = data;

            setSensorData(datum ?? null);
            setIsLoading(false);
          });
        } else {
          setErrorMsg("Error al obtener los datos del sensor");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setErrorMsg("Error al obtener los datos del sensor");
        setIsLoading(false);
      });
  }, []);

  function getBgStyle(variableName: string, value: number) {
    const variable = allVariables.find((v) => v.name === variableName);
    if (!variable) return {};
    if (!variable.ranges) return {};

    const range = variable.ranges.find((r) => r.min <= value && r.max >= value);

    if (!range) return {};

    return {
      backgroundColor: range.color,
    };
  }

  if (isLoading) return <div>Cargando...</div>;

  if (errorMsg) return <div>{errorMsg}</div>;

  if (!sensorData) return <div>No hay datos disponibles</div>;

  return (
    <div className="w-full mt-4 grid grid-cols-[1fr,100px,20px] text-[9px]">
      <div className="border-b border-b-1 border-b-black/50 col-span-3 text-center p-1 font-bold">
        Último dato del sensor
      </div>
      <div className="border-b border-b-1 border-b-black/50  p-1 font-bold">
        Variable
      </div>
      <div className="border-b border-b-1 border-b-black/50 text-center  p-1 font-bold">
        Valor
      </div>
      <div className="border-b border-b-1 border-b-black/50 text-center  p-1 font-bold"></div>

      {!!sensorData["date"] && (
        <>
          <div className="border-b border-b-1 border-b-black/50 p-1">Fecha</div>
          <div className="border-b border-b-1 border-b-black/50 text-right p-1">
            {format(new Date(sensorData["date"]["value"]), "yyyy-MM-dd HH:mm")}
          </div>
          <div className="border-b border-b-1 border-b-black/50 text-right p-1"></div>
        </>
      )}

      {variables
        .filter(
          (variable) => sensorData[variable] && sensorData[variable]["value"]
        )
        .map((variable: any) => (
          <React.Fragment key={variable}>
            <div className="border-b border-b-1 border-b-black/50 p-1">
              {variablesMap.get(variable)}
            </div>
            <div className="border-b border-b-1 border-b-black/50 text-right p-1">
              {Intl.NumberFormat("es-CO").format(sensorData[variable]["value"])}
            </div>
            <div
              className="border-b border-b-1 border-b-black/50 text-right p-1"
              style={getBgStyle(variable, sensorData[variable]["value"])}
            ></div>
          </React.Fragment>
        ))}
    </div>
  );
};
