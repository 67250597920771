import { VariableDto } from "api/variables/dto/variable.dto";
import { getVariables } from "api/variables/get-variables";
import { DropdownOption } from "models/dropdown-option.model";
import { deleteSensor } from "api/sensor/delete-sensor";
import { getSensors } from "api/sensor/get-sensors";
import CreateSensorForm from "components/forms/sensor/create-sensor-form";
import EditSensorForm from "components/forms/sensor/edit-sensor-form";
import Navbar from "components/navbar";
import { Sensor } from "models/sensor.model";
import { useEffect, useState } from "react";

export default function MySensors() {
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState<Sensor | null>(null);
  const [variables, setVariables] = useState<DropdownOption[]>([]);

  useEffect(() => {
    fetchSensors();
    fetchVariables();
  }, []);

  async function fetchSensors() {
    const response = await getSensors();

    if (!response.ok) {
      return;
    }

    const sensors = await response.json();
    setSensors(sensors);
  }

  async function fetchVariables() {
    const [variables, ok] = await getVariables();

    if (!ok) {
      return;
    }

    setVariables(variables.map((v) => ({ label: v.title, value: v.name })));
  }

  const handleDelete = async (id: string) => {
    const response = await deleteSensor(id);

    if (response.ok) {
      setSensors((prevSensors) =>
        prevSensors.filter((sensor) => sensor._id !== id)
      );
    } else {
      console.error("Failed to delete sensor");
    }
  };

  const handleUpdate = async (sensor: Sensor) => {
    setSelectedSensor(sensor);
    setIsUpdateModalOpen(true);
  };

  const handleSensorCreated = () => {
    // Refresh the sensors list after a new sensor is created
    fetchSensors();
    setIsCreateModalOpen(false); // Close the modal after creation
  };

  const handleSensorUpdated = () => {
    // Refresh the sensors list after a sensor is updated
    fetchSensors();
    setIsUpdateModalOpen(false); // Close the modal after update
  };

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div
        style={{
          background: " linear-gradient(90deg, #f9d110 35%, #f8ab0f 100%)",
        }}
        className="flex flex-col items-center pt-16 h-[calc(100vh-56px)] w-screen text-black"
      >
        <div className="max-w-6xl w-[80%] min-w-[300px] flex flex-col bg-white/70 gap-2 rounded-lg overflow-hidden">
          <div className="mompox-blue text-white flex items-center mb-4 p-4">
            <h1 className="text-2xl font-bold">Mis sensores</h1>
            <div className="flex-auto"></div>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="self-start mompox-light-blue text-white p-2 rounded-lg"
            >
              Nuevo sensor
            </button>
          </div>
          <div className="p-4 overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="text-lg font-semibold py-2 text-left">
                    Nombre
                  </th>
                  <th className="text-lg font-semibold py-2 text-left hidden lg:table-cell">
                    Variables
                  </th>
                  <th className="text-lg font-semibold py-2 text-left hidden md:table-cell">
                    API Key
                  </th>
                  <th className="text-lg font-semibold py-2 text-left">
                    {/* Actions column */}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sensors.map((sensor) => (
                  <SensorItem
                    key={sensor._id}
                    variables={variables}
                    sensor={sensor}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Create Modal */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => setIsCreateModalOpen(false)}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <CreateSensorForm
              variables={variables}
              onSensorCreated={handleSensorCreated}
            />
          </div>
        </div>
      )}
      {/* Edit Modal */}
      {isUpdateModalOpen && selectedSensor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => {
                setIsUpdateModalOpen(false);
                setSelectedSensor(null);
              }}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <EditSensorForm
              sensor={selectedSensor}
              variables={variables}
              onSensorUpdated={handleSensorUpdated}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function SensorItem({
  variables,
  sensor,
  onDelete,
  onUpdate,
}: {
  variables: DropdownOption[];
  sensor: Sensor;
  onDelete: (id: string) => Promise<void>;
  onUpdate: (sensor: Sensor) => void;
}) {
  const variablesMap = new Map(variables.map((v) => [v.value, v.label]));

  return (
    <tr className="border-t-2 border-[#c9c9c9]">
      <td className="py-2">{sensor.name}</td>
      <td className="py-2 hidden lg:table-cell">
        <div className="flex flex-wrap gap-2">
          {sensor.variables.split(",").map((v) => (
            <div
              key={v}
              className="bg-gray-200/50 rounded-lg shadow w-fit p-1 text-xs"
            >
              {variablesMap.get(v)}
            </div>
          ))}
        </div>
      </td>
      <td className="py-2 hidden md:table-cell">{sensor.apiKey}</td>
      <td className="py-2">
        <div className="flex gap-2 items-center">
          <button
            className="mompox-blue text-white p-2 rounded-lg"
            onClick={() => onUpdate(sensor)}
          >
            Editar
          </button>
          <button
            className="outline outline-2 outline-red-400 text-red-400 p-1 rounded-lg"
            onClick={() => onDelete(sensor._id)}
          >
            Eliminar
          </button>
        </div>
      </td>
    </tr>
  );
}
