"use client";
import Navbar from "components/navbar";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    async function getProfile() {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/auth/profile`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (!response.ok) {
        navigate("/login");
        return;
      }

      const profile = await response.json();

      setEmail(profile.email);
    }

    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/v1/auth/profile`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password: password || undefined }),
    });

    if (!result.ok) {
      setError("Error al actualizar el perfil");
      return;
    }

    localStorage.removeItem("accessToken");
    navigate("/login");
  };

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />

      <div
        style={{
          background: " linear-gradient(90deg, #f9d110 35%, #f8ab0f 100%)",
        }}
        className="flex flex-col items-center justify-center h-[calc(100vh-56px)] w-screen text-black"
      >
        <form
          className="max-w-2xl w-[80%] max-w-[420px] flex flex-col bg-white/70 rounded-lg py-4 px-8 gap-4"
          onSubmit={handleUpdateUser}
        >
          <h4 className="font-black text-2xl mb-4 text-mompox-blue">
            Actualizar perfil
          </h4>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
            className="bg-transparent border-b-2 border-b-[#c6c6c6] text-black p-2"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Contraseña"
            className="bg-transparent border-b-2 border-b-[#c6c6c6] text-black p-2"
          />
          <input
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            placeholder="Confirmar contraseña"
            className="bg-transparent border-b-2 border-b-[#c6c6c6] text-black p-2"
          />

          {password !== password2 ? <p>Las contraseñas no coinciden</p> : null}

          <button
            className="bg-[#1c295d] rounded-lg p-2 text-white mt-8"
            type="submit"
          >
            ACTUALIZAR PERFIL
          </button>
          {error && <p>{error}</p>}
        </form>
      </div>
    </div>
  );
}
