import { VariableDto } from "api/variables/dto/variable.dto";
import { getVariables } from "api/variables/get-variables";
import { DropdownOption } from "models/dropdown-option.model";
import { deleteAirQualityRange } from "api/air-quality-range/delete-air-quality-range";
import { AirQualityRangeDto } from "api/air-quality-range/dto/air-quality-range.dto";
import { getAirQualityRanges } from "api/air-quality-range/get-air-quality-ranges";
import CreateAirQualityRangeForm from "components/forms/ica/create-air-quality-range-form";
import Navbar from "components/navbar";
import { FC, useEffect, useState } from "react";
import UpdateAirQualityRangeForm from "components/forms/ica/update-air-quality-range-form";

export const IcasList: FC = () => {
  const [ranges, setRanges] = useState<AirQualityRangeDto[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<AirQualityRangeDto | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [variables, setVariables] = useState<DropdownOption[]>([]);

  useEffect(() => {
    fetchRanges();
    fetchVariables();
  }, []);

  async function fetchVariables() {
    const [variables, ok] = await getVariables();

    if (!ok) {
      return;
    }

    setVariables(variables.map((v) => ({ label: v.title, value: v.name })));
  }
  
  async function fetchRanges() {
    const [ranges, ok] = await getAirQualityRanges();

    if (ok) {
      setRanges(ranges);
    }

    setError("No se pudieron obtener los rangos ICA, intente nuevamente.");
  }

  const handleDelete = async (id: string) => {
    const [, ok] = await deleteAirQualityRange(id);

    if (ok) {
      setRanges([]);
      fetchRanges();
    }

    setError("No se pudo eliminar el rango ICA, intente nuevamente.");
  };

  const handleUpdate = async (range: AirQualityRangeDto) => {
    setSelectedRange(range);
    setIsUpdateModalOpen(true);
  };

  const handleRangeCreated = () => {
    // Refresh the ranges list after a new range is created
    fetchRanges();
    setIsCreateModalOpen(false); // Close the modal after creation
  };

  const handleRangeUpdated = () => {
    // Refresh the ranges list after a range is updated
    fetchRanges();
    setIsUpdateModalOpen(false); // Close the modal after update
  };

  return (
    <div className="flex flex-col bg-[#1c295d]">
      <Navbar />
      <div className="flex flex-col bg-[#fbb930] items-center pt-4 w-screen text-black">
        <div className="max-w-6xl w-[80%] min-w-[300px] flex flex-col bg-white/70 rounded-lg overflow-hidden gap-2">
          <div className="flex items-center mb-4 text-white mompox-blue  p-4 ">
            <h1 className="text-2xl font-bold">ICA por variable</h1>
            <div className="flex-auto"></div>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="self-start mompox-light-blue text-white p-2 rounded-lg"
            >
              Nuevo Registro
            </button>
          </div>

          <div className="p-4 overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="text-lg font-semibold py-2 text-left">
                    Título
                  </th>
                  <th className="text-lg font-semibold py-2 text-left hidden lg:table-cell">
                    Título calidad
                  </th>
                  <th className="text-lg font-semibold py-2 text-left hidden lg:table-cell w-[120px]">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {ranges.map((range) => (
                  <RangeItem
                    key={range._id}
                    range={range}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Create Modal */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <div className="w-full max-w-md p-4 bg-white/70 rounded-lg">
              <button
                onClick={() => setIsCreateModalOpen(false)}
                className="text-red-500 font-bold float-right mb-4"
                data-modal-hide="defaultModal"
              >
                x
              </button>
              <CreateAirQualityRangeForm
                onAirQualityRangeCreated={handleRangeCreated}
              />
            </div>
          </div>
        </div>
      )}

      {/* Edit Modal */}
      {isUpdateModalOpen && selectedRange && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <button
              onClick={() => {
                setIsUpdateModalOpen(false);
                setSelectedRange(null);
              }}
              className="text-red-500 font-bold float-right mb-4"
            >
              X
            </button>
            <UpdateAirQualityRangeForm
              airQualityRange={selectedRange}
              onAirQualityRangeUpdated={handleRangeUpdated}
            />
          </div>
        </div>
      )}
    </div>
  );
};

interface RangeItemProps {
  range: AirQualityRangeDto;
  onDelete: (id: string) => Promise<void>;
  onUpdate: (range: AirQualityRangeDto) => void;
}

const RangeItem: FC<RangeItemProps> = ({ range, onDelete, onUpdate }) => {
  return (
    <tr className="border-t-2 border-[#c9c9c9]">
      <td className="py-2">{range.title}</td>
      <td className="py-2">{range.quality_title}</td>
      <td className="py-2">
        <div className="flex gap-2">
          <button
            className="mompox-blue text-white p-2 rounded-lg"
            onClick={() => onUpdate(range)}
          >
            Editar
          </button>
          <button
            className="outline outline-2 outline-red-400 text-red-400 p-1 rounded-lg"
            onClick={() => onDelete(range._id)}
          >
            Eliminar
          </button>
        </div>
      </td>
    </tr>
  );
};
