import { ApiResponse } from "models/api-response";
import { IcaValueComputed } from "models/ica";

export async function getIcaForSensor(sensorId: string): Promise<
  ApiResponse<{
    [key: string]: IcaValueComputed;
  }>
> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/air-quality-ranges/icas/${sensorId}`,
      {
        method: "GET",
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    const data = await response.json();

    return [data, true];
  } catch (error) {
    return [null, false];
  }
}
