import { CreateRangeDto } from "api/variables/dto/create-range.dto";
import { VariableDto } from "api/variables/dto/variable.dto";
import { updateVariable } from "api/variables/update-variable";
import { useState } from "react";
import RangesForm from "./ranges-form";

export default function UpdateVariableForm({
  variable,
  onVariableUpdated,
}: {
  variable: VariableDto;
  onVariableUpdated: () => void;
}) {
  const [name, setName] = useState(variable.name);
  const [title, setTitle] = useState(variable.title);
  const [unit, setUnit] = useState(variable.unit);
  const [useRanges, setUseRanges] = useState(variable.ranges.length > 0);
  const [ranges, setRanges] = useState<CreateRangeDto[]>(variable.ranges);
  const [error, setError] = useState("");

  const handleUpdateVariable = async () => {
    setError("");

    // We must check if 'name' follows the format of a valid variable name
    // before sending it to the backend, using a regular expression
    if (!/^[a-zA-Z_][a-zA-Z0-9_]*$/.test(name)) {
      setError("El nombre de la variable no es válido");
      return;
    }

    try {
      const result = await updateVariable(variable._id, {
        name,
        title,
        unit,
        useRanges,
        ranges,
      });

      if (!result.ok) {
        setError("Hubo un error al actualizar la variable");
        return;
      }

      onVariableUpdated();
    } catch (e) {
      setError("Hubo un error al actualizar la variable");
    }
  };

  return (
    <div className="flex gap-2 flex-col p-2 text-black">
      <div className="flex flex-col gap-1">
        <label>Nombre (para subida de datos)</label>
        <input
          type="text"
          placeholder="Nombre"
          value={name}
          className="p-2 rounded-lg border"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Título (para mostrar en reportes)</label>
        <input
          type="text"
          placeholder="Título"
          value={title}
          className="p-2 rounded-lg border"
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col gap-1">
        <label>Unidad</label>
        <input
          type="text"
          placeholder="Unidad"
          value={unit}
          className="p-2 rounded-lg border"
          onChange={(e) => setUnit(e.target.value)}
          required
        />
      </div>
      <div className="flex gap-2 items-center">
        <label>Usa rangos?</label>
        <input
          type="checkbox"
          checked={useRanges}
          onChange={(e) => setUseRanges(e.target.checked)}
        />
      </div>

      {useRanges && <RangesForm ranges={ranges} setRanges={setRanges} />}
      {error && <div className="text-red-500">{error}</div>}
      <button
        className="p-2 mompox-blue text-white rounded-lg"
        onClick={handleUpdateVariable}
      >
        Actualizar variable
      </button>
    </div>
  );
}
