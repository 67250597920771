import { FormEvent, useState } from "react";
import { useAuth } from "hooks/use-auth";
import { login } from "api/auth/login";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login: setIsLoggedIn } = useAuth();

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("accessToken", data.access_token);
        setIsLoggedIn();
        navigate("/");
      } else {
        setError(data.message ?? "Credenciales incorrectas.");
      }
    } catch (err) {
      console.error(err);
      setError("Ha ocurrido un error, intente nuevamente.");
    }
  };

  const handleResendConfirmation = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/v1/auth/resend-confirmation-email`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setError(
          "Correo de confirmación reenviado, revise su bandeja de entrada."
        );
      } else {
        setError(data.message ?? "Ha ocurrido un error.");
      }
    } catch (err) {
      console.error(err);
      setError("Ha ocurrido un error, intente nuevamente.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen text-white login-bg">
      <img
        aria-hidden
        src="/logos/Logo Mompox inteligente_despiece-07.svg"
        alt="App logo"
        width={256}
        height={128}
        className="mx-auto mb-8"
      />
      <form
        className="max-w-2xl w-[80%] min-w-[300px] max-w-[400px] flex flex-col rounded-lg py-2 px-4 lg:px-12 lg:py-4 gap-4"
        onSubmit={handleLogin}
        style={{
          background:
            "linear-gradient(90deg, rgba(246,170,15,0.19762499726453087) 35%, rgba(246,170,15,1) 100%)",
        }}
      >
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="bg-transparent border-b-2 border-b-white text-black p-2"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Contraseña"
          required
          className="bg-transparent border-b-2 border-b-white text-black p-2"
        />
        <div className="grid grid-cols-[1fr,1fr] items-center">
          <div className="flex items-center gap-2">
            <input type="checkbox" /> Recuerdame
          </div>
          <button className="bg-[#1c295d] rounded-lg p-2 w-full" type="submit">
            INGRESAR
          </button>
        </div>
        <button
          className="rounded-lg py-1 px-8 bg-[#f2a60a] w-fit mx-auto mt-8"
          onClick={() => navigate("/register")}
        >
          Registrarme
        </button>
        {error && <p>{error}</p>}
        {error === "Email no esta confirmado" && (
          <button
            className="rounded-lg p-2"
            onClick={() => {
              setError(null);
              handleResendConfirmation();
            }}
          >
            Reenviar correo de confirmación
          </button>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
