import Select from "react-select";
import { FormEvent, useState } from "react";
import { DropdownOption } from "models/dropdown-option.model";
import { createSensor } from "api/sensor/create-sensor";
import { SENSOR_TYPES } from "constants/sensor-types";
import { Instructions } from "./sensor-instructions";

export default function CreateSensorForm({
  onSensorCreated,
  variables,
}: {
  onSensorCreated: () => void;
  variables: DropdownOption[];
}) {
  const [name, setName] = useState("");
  const [selectedVariables, setSelectedVariables] = useState<DropdownOption[]>(
    []
  );
  const [sensorType, setSensorType] = useState("weather-station");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [error, setError] = useState<string | null>(null);

  const availableVariables: DropdownOption[] = variables;
  const sensorTypes: DropdownOption[] = SENSOR_TYPES.map((type) => ({
    label: type.name,
    value: type.id,
  }));

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await createSensor({
      name,
      variables: selectedVariables.map((v) => v.value).join(","),
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      type: sensorType,
    });

    if (response.ok) {
      onSensorCreated();
      setName("");
      setSelectedVariables([]);
      setLatitude("");
      setLongitude("");
      setError(null);
    } else {
      setError("Hubo un error al crear el sensor, intente de nuevo.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-black">
      <h2 className="text-2xl text-mompox-blue font-bold">Nuevo sensor</h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Name"
        required
        className="p-2 rounded-lg border"
      />
      <Select
        options={availableVariables}
        isMulti
        value={selectedVariables}
        onChange={(selected) =>
          setSelectedVariables(selected as DropdownOption[])
        }
        placeholder="Variables"
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <Select
        options={sensorTypes}
        value={sensorTypes.find((option) => option.value === sensorType)}
        onChange={(selected) => setSensorType(selected!.value)}
        placeholder="Tipo de sensor"
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <input
        type="number"
        value={latitude}
        onChange={(e) => setLatitude(e.target.value)}
        placeholder="Latitude"
        required
        className="p-2 rounded-lg border"
      />
      <input
        type="number"
        value={longitude}
        onChange={(e) => setLongitude(e.target.value)}
        placeholder="Longitude"
        required
        className="p-2 rounded-lg border"
      />
      <button type="submit" className="mompox-blue text-white p-2 rounded-lg">
        Nuevo sensor
      </button>
      {error && <p className="text-red-500">{error}</p>}
      <Instructions />
    </form>
  );
}
