import { CreateVariableDto } from "./dto/create-variable.dto";

export function createVariable(variable: CreateVariableDto) {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/variables`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(variable),
  });
}