export function getLastSensorData(sensorId: string) {
  const queryParams = new URLSearchParams({
    limit: "1",
    type: "Sensor",
    id: sensorId,
  });

  const baseUrl = `${process.env.REACT_APP_ORION_URL}/v2/entities`;

  return fetch(`${baseUrl}?${queryParams.toString()}`, {});
}
