import { ApiResponse } from "@models/api-response";
import { VariableDto } from "./dto/variable.dto";

export async function getVariables(): Promise<ApiResponse<VariableDto[]>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/variables`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    const result = await response.json();
    return [result, true];
  } catch (error) {
    return [null, false];
  }
}
