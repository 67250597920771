import { ApiResponse } from "@models/api-response";
import { AirQualityRangeDto } from "./dto/air-quality-range.dto";
import { UpdateAirQualityRangeDto } from "./dto/update-air-quality-range.dto";

export async function updateAirQualityRange(
  id: string,
  data: UpdateAirQualityRangeDto
): Promise<ApiResponse<AirQualityRangeDto>> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/v1/air-quality-ranges/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      return [null, false];
    }

    return [{} as any, true];
  } catch (error) {
    return [null, false];
  }
}
